
export default {

    newGameButton: 'New game',
    newGameAttentionLabel: 'Start a new game?',
    newGameEasyButton: 'Easy',
    newGameMediumButton: 'Medium',
    newGameHardButton: 'Hard',
    newGameExpertButton: 'Expert',

    modals: {
        newGame: {
            title: 'Start new game'
        },
        settings: {
            title: 'Settings',
            timerLabel: 'Timer enabled',
            themeLabel: 'Dark mode',
        },
        statistic: {
            title: 'Statistic',
            selectLevels: ['easy', 'medium', 'hard', 'expert'],
            gamesCountLabel: 'Games',
            gamesBestLabel: 'Best',
            gamesAverageLabel: 'Average',
            legendTitle: 'Average time',
            legendDailyTitle: 'daily',
            legendLifeTimeTitle: 'lifetime',
            resetButtonLabel: 'reset',
            resetAttentionLabel: 'Reset? Are you sure?',
        },
        info: {
            title: 'How to Play',
            content: <div>
                <span className="metaAboutText">Enjoy Word Search, the classic puzzle game with thousands of free puzzles! Try our unique hard and expert word search modes!</span><br /><span className="metaAboutText">About Word Search:</span><br /><span className="metaAboutText">Your goal is to find the list of words hidden in the grid. Words can be discovered horizontally, vertically, diagonally or backwards. Swipe and drag to select words in the grid once they are found, starting with the first letter of the word. For an added challenge try our unique hard and expert word search modes which obscure word lists.</span><br /><span className="metaAboutText">The words used in all of our puzzles are standard English words and are of greater substance than most other word search puzzle games. This makes our game a fun, useful tool for building your vocabulary and learning new words. Tap on any word in the word list to look up its definition in the dictionary.</span><br /><span className="metaAboutText">Our classic Word Search game also saves your unfinished games so you can come back and finish them whenever you want!</span><br /><span className="metaAboutText">Track your best and average game times over history with our stats tracker.</span>
            </div>,
        },
    }

};
