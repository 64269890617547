
export const minOopt = {
    backwardsProbability: 0.3,
    upperCase: true,
    diacritics: true
};
export const options = {
    easy: {
        ...minOopt,
        cols: 7,
        rows: 7,
        maxWords: 7,
    },
    medium: {
        ...minOopt,
        cols: 9,
        rows: 9,
        maxWords: 11,
    },
    hard: {
        ...minOopt,
        cols: 12,
        rows: 12,
        maxWords: 14,
    },
    expert: {
        cols: 15,
        rows: 15,
        maxWords: 16,
    },
}
