import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./app.css"
import { options, WORDS } from "./conf";
import WordSearch from "@blex41/word-search";
import { clearKilledWords, paintKilledWords, paintPreWord, WordsGrid } from "./WordsGrid";
import NavBar from "./NavBar";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Timer from "./Timer";
import labels from "./labels";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { XCircleIcon } from "@heroicons/react/outline";
export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function App() {
    const [gameOver, setGameOver] = useState(true);
    const [isOpenFinish, setIsOpenFinish] = useState(false);
    const [ws, createWs] = useState();
    const [killedWords, setKilledWords] = useState([]);
    const [hinted, setHinted] = useState({ count: 0, word: {} });
    const [timerEnabled, setTimerEnabled] = useState(localStorage.getItem('timer_enabled') === 'true');
    const changeTimerEnabled = useCallback(() => {
        setTimerEnabled(te => {
            localStorage.setItem('timer_enabled', !te)
            return !te;
        })
    });

    //хранилище размеров
    const [sizes, setSizes] = useState({});
    const { gridHeight, gridWidth } = sizes;
    //вычисление и мемоиз размера игрового поля
    const boxSize = useMemo(() => Math.min(gridHeight, gridWidth), [
        gridHeight, gridWidth
    ]);
    const rootRef = useRef();
    //снятие реальных размеров блоков
    const getBoxSize = useCallback(() => {
        const grdPlc = rootRef.current.querySelector(".gridPlace");
        const prevMaxHeight = grdPlc.style.maxHeight;
        grdPlc.style.maxHeight = '500px';
        const gridHeight = grdPlc.clientHeight;
        const gridWidth = grdPlc.clientWidth;
        grdPlc.style.maxHeight = prevMaxHeight;
        setSizes({ gridHeight, gridWidth });
    }, [rootRef,]);

    //установка подсказки
    const hadleSetHinted = useCallback(word => setHinted(({ count }) => ({ count: count + 1, word })), []);

    //создание новой игры
    const createGame = useCallback(async (lvl) => {
        if (killedWords.length > 0 && !window.confirm(labels.newGameAttentionLabel))
            return;
        //очищаем обводки угаданных слов
        clearKilledWords();
        //включаем игру
        setGameOver(false);
        setIsOpenFinish(false);
        //очищаем список отгаданных слов
        setKilledWords([]);
        //очищаем подсказку
        setHinted({ count: 0 });
        const resp = await fetch('words.json');
        const words = await resp.json();
        //генерируем новую игру
        const newWs = new WordSearch({ ...options[lvl], dictionary: words });
        createWs(newWs);
        localStorage.setItem('game_dump', JSON.stringify(newWs.dump()))
        //сбрас таймер
        localStorage.setItem('timer', 0)
        //сбрас отгаданныъ слов в хранилище
        localStorage.removeItem('killed_words')
        //запоминаем уровень сложности
        localStorage.setItem('level', lvl)
    }, [killedWords]);

    //определяем размеры при первом запуске игры и в случае ресайза
    useEffect(() => {
        setTimeout(getBoxSize, 500)
        // getBoxSize();
        //слушаем ресайз для изменения размеров игрового поля
        window.addEventListener('resize', getBoxSize);
        return () => {
            window.removeEventListener('resize', getBoxSize);
        };
    }, []);

    //restore from backup
    useEffect(() => {
        // 1. при запуске попробовать взять бэкап
        const backup = localStorage.getItem('game_dump');
        // 2. если бэкап есть
        if (backup) {
            // 2.1. восстановить его в ws
            const lvl = localStorage.getItem('level');
            const restoredWS = new WordSearch(options[lvl]).load(JSON.parse(backup))
            createWs(restoredWS)
            // 2.2. восстановить из хранилища список отгаданных слов
            const killedW = localStorage.getItem('killed_words')
            if (killedW) {
                setKilledWords(JSON.parse(killedW))
            }
            //включить игру
            setGameOver(false);
            setIsOpenFinish(false);
        } else {
            createGame('medium');
        }
    }, [])

    //отрисовка отгаданных слов при первой загрузке или после ресайза
    useEffect(() => {
        setTimeout(() => {
            ws?.words
                .filter(({ clean }) => killedWords.includes(clean))
                .forEach(({ path, clean }) => {
                    const bckp_start = path[0];
                    const bckp_end = path.slice(-1)[0];
                    paintKilledWords(bckp_start, bckp_end, boxSize)
                })
        }, 200)
    }, [boxSize])
    //проверка на угаданное слово, отрисовка предварительного пути идли отгаданного слова
    const checkWord = useCallback(({ start, end, }) => {
        //работаем только если игра не закончилась
        if (gameOver) return;
        const currentWords = ws.words.map(({ clean }) => clean);
        let selectedWord = ws.read(start, end);
        const dias = {};
        let directDias = 'y';
        let currEnd = { ...end };
        let steps = 0;

        while (!selectedWord && steps < 20) {
            const diasName = `${directDias}Dias`;
            const currDias = { ...(dias[diasName] || end) };
            // // если стартовая координата меньше смещаемой -- вычитаем
            if (end[directDias] > start[directDias]) {
                currDias[directDias] -= 1;
            }//иначе прибавляем кординату
            else {
                currDias[directDias] += 1;
            }
            selectedWord = ws.read(start, currDias);
            directDias = directDias === 'y' ? 'x' : 'y';
            dias[diasName] = currEnd = { ...currDias };
            // console.log(`wwwwwa ${steps}`, {selectedWord, directDias, currEnd})
            steps++;
        }
        //когда по координатам старта и окончания есть слово
        //проверяем его не вхождение в список размещенных слов и проверяем, что слово еще не отгадано
        if (currentWords.includes(selectedWord) && !killedWords.includes(selectedWord)) {
            //запоминаем угаданные слова в массив
            setKilledWords(a => {
                const currentKilledWords = [...a, selectedWord];
                //и в локальное хранилище
                localStorage.setItem('killed_words', JSON.stringify(currentKilledWords))
                //делаем обводку на канвасе с угаданными словами
                paintKilledWords(start, currEnd, boxSize)
                return currentKilledWords
            })
            //убиваем подсказку
            setHinted(h => ({ ...h, word: {} }));
            //заканчиваем игру при отгадывании всех слов
            if (killedWords.length === ws.words.length - 1) {
                setGameOver(true)
                setIsOpenFinish(true);
                localStorage.removeItem('game_dump')
                //сбрасываем таймер
                // localStorage.setItem('timer', 0)
                //сбрасываем отгаданные слова в хранилище
                localStorage.removeItem('killed_words')
                //сохранить статистику
                //TODO изъять из хранилища текущую стату и добавит ьв нее новую игру с указанием даты, уровня сложности и показателя времени
                const storageStat = localStorage.getItem('stat');
                const stat = storageStat ? JSON.parse(storageStat) : [];
                stat.push({
                    level: localStorage.getItem('level'),
                    time: timerEnabled && localStorage.getItem('timer'),
                    date: new Date(),
                    hints: hinted.count,
                });
                localStorage.setItem('stat', JSON.stringify(stat));
            }
        }
        //если слово не угадано -- подсвечиваем путь серым цветом
        paintPreWord(start, currEnd, boxSize)
    }, [ws, killedWords, hinted, timerEnabled,]);

    const startGames = (onCreate = () => { }) => <>
        <div>
            <Button onClick={e => { createGame('easy'); onCreate(); }}>{labels.newGameEasyButton}</Button>
            <Button onClick={e => { createGame('medium'); onCreate(); }}>{labels.newGameMediumButton}</Button>
            <Button onClick={e => { createGame('hard'); onCreate(); }}>{labels.newGameHardButton}</Button>
            <Button onClick={e => { createGame('expert'); onCreate(); }}>{labels.newGameExpertButton}</Button>
        </div>
    </>

    const dt = new Date(Number(localStorage.getItem('timer')) * 1000 || 0);
    const gameWords =
        localStorage.getItem('level') === 'expert' ?
            ws?.words.map(({ word, clean, ...w }) => ({
                word: killedWords.includes(clean) ? word : '_  '.repeat(word.length),
                clean,
                ...w
            }))
            :
            ws?.words;

    return <Box
        className="wordsRoot"
        ref={rootRef}
        sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            touchAction: 'none',
        }}
    >
        <NavBar
            title={timerEnabled && ws && !gameOver ? <Timer ws={ws} gameOver={gameOver} /> : 'Word Search'}
            timerEnabled={timerEnabled}
            onChangeTimerEnabled={changeTimerEnabled}
            startGames={startGames}
        />
        <div className="gridPlace" style={{ maxHeight: boxSize || 'auto' }}>
            {ws && Boolean(boxSize) && <WordsGrid grid={ws?.grid} boxSize={boxSize} checkWord={checkWord} hinted={hinted.word} />}
        </div>
        <WordsList words={gameWords} killedWords={killedWords} setHint={hadleSetHinted} />
        {gameOver && isOpenFinish &&
            <Dialog open
                disableEnforceFocus
                PaperProps={{ style: { pointerEvents: 'auto' } }}
                style={{
                    textAlign: "center",
                    pointerEvents: 'none',
                    borderRadius: '.5rem'
                }}
                hideBackdrop
            >
                <DialogTitle>Game is over!<XCircleIcon className='icon closeIcon' onClick={e => setIsOpenFinish(false)} /></DialogTitle>
                <DialogContent>
                    {timerEnabled && <div>Your time: {dt.getMinutes()}:{dt.getSeconds() < 10 && '0'}{dt.getSeconds()}</div>}
                    <div style={{ marginBottom: 24 }}>Used hints: {hinted.count}</div>
                    start new game:
                    {startGames()}
                </DialogContent>
            </Dialog>
        }
    </Box>
};

const WordsList = memo(({ words, killedWords, setHint }) => <div className="words">{
    words?.map(({ word, clean, ...w }, i) =>
        <span
            key={`words-${word}-${i}`}
            onClick={e => !killedWords.includes(clean) && setHint({ word, clean, ...w })}
            className={killedWords.includes(clean) ? 'killed' : ""}
        >
            {word}
        </span>
    )
}</div>);


// export default App;
export default function ToggleColorMode() {
    const savedTheme = localStorage.getItem('theme');
    const [mode, setMode] = React.useState(savedTheme || 'light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const newTheme = (prevMode === 'light' ? 'dark' : 'light');
                    localStorage.setItem('theme', newTheme);
                    return newTheme;
                });
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: "'Nunito', sans-serif"
                },
                palette: {
                    mode,
                    ...(mode === 'dark' &&
                    {
                        // palette values for dark mode
                        background: {
                            default: '#0f172a',
                            modal: '#1f2937',
                        },
                    }),
                    // background:{default: {dark:'#0f172a'}}
                },
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
