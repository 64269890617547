import { memo } from "react";

let startCoord;
let touchMoveCoord;

export const WordsGrid = memo(({ grid, boxSize, checkWord, hinted }) => {
  //обработка тачнаведения
  const handleTouchMove = e => {
    e.preventDefault();
    const { clientX, clientY } = e.touches[0];
    const currentSymb = document.elementFromPoint(clientX, clientY);
    if (!document.querySelector('.grid').contains(currentSymb)) return false;
    const { x, y } = currentSymb.dataset;
    // console.log('handle touch', { clientX, clientY, x, y, currentSymb, startCoord });
    if (JSON.stringify({ x, y }) !== JSON.stringify(touchMoveCoord)) {
      touchMoveCoord = { x: Number(x), y: Number(y) };
      checkWord({ start: startCoord, end: touchMoveCoord });
    }
  }
  const cellWidth = boxSize / grid.length;
  return <div className="grid" style={{ maxWidth: boxSize, maxHeight: boxSize }}>
    {grid?.map((gridRow, y) => {
      const rowKey = JSON.stringify(gridRow);
      return <div key={JSON.stringify(gridRow)} className={'gridRow'}>
        {gridRow?.map((symbol, x) =>
          <div
            key={`${rowKey}-${x}-${y}`}
            {...{
              'data-x': x,
              'data-y': y,
            }}
            className={'gridCell'}
            style={{
              width: cellWidth,
              fontSize: cellWidth * 0.4,
              //если установлена подсказка, проверяем текущий символ и подсвечиваем
              background: hinted?.path && (hinted?.path[0]?.x === x && hinted?.path[0]?.y === y) ?
                'rgba(100,150,50,0.3)' : 'inherit',
            }}
            onPointerDown={e => { startCoord = { x, y } }}
            onMouseOver={e => {
              if (startCoord) {
                checkWord({ start: startCoord, end: { x, y } });
              }
            }}
            // onTouchStart= {e => e.preventDefault()}
            onTouchMove={handleTouchMove}
          >
            {symbol}
          </div>
        )}
      </div>
    })}
    <Canvas id="killedCanvas" size={boxSize} />
    <Canvas id="preCanvas" size={boxSize} zIndex={5} />
  </div>
})
const Canvas = ({ size, id, zIndex = 0 }) =>
  <canvas
    id={id}
    width={size}
    height={size}
    style={{
      position: "absolute",
      zIndex,
      opacity: 0.3
    }}
  />


// tools для работы с канвасами
// функция для рисования в заданном канвасе линии по заданным координатам, заданного цвета
const paintLine = (canv, { start, end },
  color = `rgba(${localStorage.getItem('theme') === 'dark' ? '250,250,250' : '0,0,0'}, 0.3)`//"rgba(0,0,0,0.3)"
) => {
  //получаем конеткст для рисования
  const ctx = canv.getContext('2d');

  //получаем размер канваса и вычисляем размер ячейки
  const canvS = canv.height;
  const symCount = document.querySelectorAll('.gridRow').length;
  const boxS = canvS / symCount;

  //вычисляем координаты с поправкой на размер канваса 
  const [startCoord, endCoord] =
    [start, end]
      .map(({ x, y }) => ({ x: x * boxS + (boxS / 2), y: y * boxS + (boxS / 2) }));

  //рисуем линию
  ctx.strokeStyle = color;
  ctx.lineWidth = boxS * 0.7;
  ctx.lineCap = "round";
  ctx.beginPath();
  ctx.moveTo(startCoord.x, startCoord.y);
  ctx.lineTo(endCoord.x, endCoord.y);
  ctx.stroke();
};
//функция очистки заданного канваса
const clearCanv = (canv) => {
  if (!canv) return;
  canv
    .getContext('2d')
    .clearRect(0, 0, canv.width, canv.height);
}
//функция(обертка над paintLine) рисования отгаданного слова
export const paintKilledWords = (start, end) => {
  // console.log('start paint killed', { start, end });
  //генерируем цвет нового слова
  let hexString = "0123456789abcdef";
  let hexCode = "#";
  for (let i = 0; i < 6; i++) {
    hexCode += hexString[Math.floor(Math.random() * hexString.length)];
  }
  //запускаем рисование в нужном канвасе слова сгенерированным цветом
  paintLine(document.getElementById('killedCanvas'), { start, end }, hexCode)
};
//функция(обертка над clearCanv) очищает канвас отгаданных слов
export const clearKilledWords = () => {
  // console.log('clear killed words');
  clearCanv(document.getElementById('killedCanvas'))
}
//функция(обертка над paintLine)  рисует на канвасе с прицельным path
export const paintPreWord = (start, end) => {
  //очищаем предыдущий рисунок
  clearPreWords();
  //рисуем новую линию полупрозрачным цветом(по-умолчанию)
  paintLine(document.getElementById('preCanvas'), { start, end })
};
//функция(обертка над clearCanv) очищает канвас с прицельным path
const clearPreWords = () => {
  // console.log('clearPreWords');
  clearCanv(document.getElementById('preCanvas'))
}

//сброс стартовой и конечной координаты при отпускании мыши или завершении тача
export const resetStartCoord = () => {
  // console.log('reset start coord')
  if (startCoord) clearPreWords();
  startCoord = null;
  touchMoveCoord = null;
};
window.addEventListener('mouseup', resetStartCoord)
window.addEventListener('touchend', resetStartCoord)
