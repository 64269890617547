import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ColorModeContext } from './App';
import {
    ChartBarIcon,
    CogIcon,
    InformationCircleIcon,
    XCircleIcon
} from '@heroicons/react/outline';
import 'chartist/dist/index.css';
import { LineChart, FixedScaleAxis, AutoScaleAxis } from 'chartist';
import labels from './labels';

const Stat = ({ stat }) => {

    const [lvl, changeLvl] = useState('easy');
    // const lvls = ['easy', 'medium', 'hard', 'expert'];
    let best = 99999;
    let bad = 0;
    let allSum = 0;
    const defaultDataSet = {};
    let now = new Date();
    now.setDate(now.getDate() - 7)
    let step = 7;
    while (step) {
        const currNow = new Date(now);
        const currentDate = new Date(currNow).toLocaleString("en-US", {
            month: 'numeric',
            day: 'numeric'
        })
        defaultDataSet[currentDate] = {
            date: currNow,
            count: 0,
            time: 0,
            average: 0,
        };
        now.setDate(now.getDate() + 1)
        step -= 1;
    }
    const normalStat = stat
        .filter(({ level }) => level === lvl)
        .reduce((acc, el, i) => {
            const elTime = Number(el.time || 0);

            if (best > elTime) best = elTime;
            if (bad < elTime) bad = elTime;

            const currentDate = new Date(el.date).toLocaleString("en-US", {
                month: 'numeric',
                day: 'numeric'
            })
            acc[currentDate] = acc[currentDate] || { date: el.date, count: 0, time: 0, average: 0 };
            acc[currentDate].count += 1;
            acc[currentDate].time += elTime;
            allSum += elTime;
            acc[currentDate].average = allSum / (i + 1);
            return acc;
        }, { ...defaultDataSet });

    const [daily, lifeTime] = Object.keys(normalStat).reduce(([dly, lft], dayName, i) => {
        const { date, time, count, average } = normalStat[dayName];
        dly.push({ x: new Date(date), y: new Date((time / count || 0) * 1000) });
        const lastAverage = lft[lft.length - 1]?.y || 0;
        lft.push({ x: new Date(date), y: average ? new Date(average * 1000) : lastAverage });
        return [dly, lft]
    }, [[], []]);


    useEffect(() => {
        new LineChart(
            '.statChart',
            {
                series: [{
                    name: 'daily',
                    data: daily
                }, {
                    name: 'lifeTime',
                    data: lifeTime
                }]
            },
            {
                axisX: {
                    type: FixedScaleAxis,
                    divisor: 6,
                    labelInterpolationFnc: value => {
                        return new Date(value).toLocaleString("en-US", {
                            month: 'numeric',
                            day: 'numeric'
                        })
                    },
                },
                axisY: {
                    type: AutoScaleAxis,
                    labelInterpolationFnc: value => {
                        return new Date(value).toLocaleString(undefined, { minute: '2-digit', second: '2-digit' })
                    }
                },
                series: {
                    daily: {
                        showArea: true,
                        showPoint: false,
                    },
                    lifeTime: {
                        showPoint: false,
                        classNames: 'hello'
                    },
                }
            },
        );

    }, [lvl, stat])

    return <div className={'statRoot'}>
        <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', mb: 2, cursor: 'pointer' }}>
            {labels?.modals.statistic.title}
        </Typography>
        <select onChange={e => changeLvl(e.target.value)}>
            {labels.modals.statistic.selectLevels.map(l =>
                <option key={`stat-lvls-${l}`} value={l}>
                    {l}
                </option>
            )}
        </select>
        <div className={'statInfo'}>
            <div><span>{labels.modals.statistic.gamesCountLabel}</span>
                <div>{stat.length}</div>
            </div>
            <div><span>{labels.modals.statistic.gamesBestLabel}</span>
                <div>{new Date((best !== 99999 ? best : 0) * 1000).toLocaleString(undefined, { minute: '2-digit', second: '2-digit' })}</div>
            </div>
            <div><span>{labels.modals.statistic.gamesAverageLabel}</span>
                <div>{new Date((allSum / stat.length || 0) * 1000).toLocaleString(undefined, { minute: '2-digit', second: '2-digit' })}</div>
            </div>
        </div>
        <div className={"chartLegend"}>
            <span>{labels.modals.statistic.legendTitle}</span>
            <div className='legendItem'>
                <div className='legendLine'></div>
                <div className='legendLabel'>{labels.modals.statistic.legendDailyTitle}</div>
            </div>
            <div className='legendItem'>
                <div className='legendLine' style={{ borderStyle: 'dotted', borderColor: '#f05b4f' }}></div>
                <div className='legendLabel'>{labels.modals.statistic.legendLifeTimeTitle}</div>
            </div>
        </div>
        <div className={`statChart ${localStorage.getItem('theme')}`}></div>
    </div>
};

const NavBar = ({ title, timerEnabled, onChangeTimerEnabled, startGames }) => {

    const storageStat = localStorage.getItem('stat');
    const [stat, setStat] = useState(storageStat ? JSON.parse(storageStat) : []);
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    const handleOpenDialog = (contentName) => {
        setIsOpen(contentName);
        localStorage.setItem('pauseTimer', 'true');
        dialogContentMap[contentName]?.onOpen && dialogContentMap[contentName]?.onOpen()
    };
    const handleCloseDialog = useCallback(() => {
        setIsOpen(false);
        localStorage.setItem('pauseTimer', 'false');
    });

    const dialogContentMap = {
        info: {
            title: labels?.modals?.info?.title,//'Info page',
            content: labels?.modals?.info?.content,
        },
        stat: {
            content: <>
                <Stat stat={stat} />
                <div
                    style={{
                        cursor: 'pointer', opacity: 0.45,
                        fontSize: 14,
                        textDecoration: 'underline'
                    }}
                    onClick={() => {
                        if (window.confirm(labels.modals.statistic.resetAttentionLabel)) {
                            // Save it!
                            localStorage.setItem('stat', []);
                            setStat([]);
                        }

                    }}
                >
                    {labels.modals.statistic.resetButtonLabel}
                </div>
            </>,
            onOpen: () => setStat(JSON.parse(localStorage.getItem('stat') || '[]')),
        },
        settings: {
            title: labels.modals.settings.title,//'Settings',
            content: <>
                <FormControlLabel
                    control={
                        <Switch checked={timerEnabled} onChange={onChangeTimerEnabled} color={'success'} />
                    }
                    label={labels.modals.settings.timerLabel}//"Timer enabled"
                    componentsProps={{ typography: { sx: { opacity: 0.45, mr: 'auto' } } }}
                    labelPlacement={'start'}

                    sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,0.2)', m: 0, mb: 2, pb: 2 }}
                />
                <FormControlLabel
                    control={
                        <Switch checked={theme.palette.mode === 'dark'} onChange={colorMode.toggleColorMode} color={'success'} />
                    }
                    label={labels.modals.settings.themeLabel}//"Dark mode"
                    componentsProps={{ typography: { sx: { opacity: 0.45, mr: 'auto' } } }}
                    labelPlacement={'start'}
                    sx={{ width: '100%', m: 0, mb: 2 }}
                />
            </>
        },
        newGame: {
            title: labels.modals.newGame.title,//'Start new game',
            content: <div style={{ textAlign: 'center' }}>{startGames(handleCloseDialog)}</div>,
        }
    };

    return <div className='header'>
        <div className="info">
            <InformationCircleIcon className="icon" onClick={e => handleOpenDialog('info')} />
        </div>
        <div className="timer">{title}</div>
        <div className="menu">
            <Button onClick={e => handleOpenDialog('newGame')} sx={{ fontWeight: '700!important' }}>{labels.newGameButton}</Button>
            <ChartBarIcon className='icon' onClick={e => handleOpenDialog('stat')} />
            <CogIcon className='icon' onClick={e => handleOpenDialog('settings')} />
        </div>
        {isOpen && <Dialog open={true} onClose={handleCloseDialog} PaperProps={{
            style: { borderRadius: '.5rem', },
            sx: { bgcolor: 'background.modal', backgroundImage: 'none' }
        }}
            BackdropProps={{ style: { backgroundColor: "rgb(107 114 128/0.75)" } }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>{dialogContentMap[isOpen]?.title}
                {/* <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton> */}
                <XCircleIcon className='icon closeIcon' onClick={handleCloseDialog} />
            </DialogTitle>
            <DialogContent>
                {dialogContentMap[isOpen]?.content}
            </DialogContent>
        </Dialog>}
    </div>
};

export default NavBar;