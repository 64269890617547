import { useEffect, useState } from "react";

let timerId;
const Timer = ({ ws, gameOver }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setSeconds(Number(localStorage.getItem('timer') || 0));
    if (!gameOver) {
      clearInterval(timerId)
      timerId = setInterval(() => {
        if (document.hasFocus() && localStorage.getItem('pauseTimer') !== 'true') {
          setSeconds(s => {
            const currentSeconds = s + 1;
            localStorage.setItem('timer', currentSeconds);
            return currentSeconds;
          });
        }
      }, 1000)
    }
    else {
      clearInterval(timerId)
    }
    return () => {
      clearInterval(timerId)
    }
  }, [ws, gameOver]);

  const dt = new Date(seconds * 1000);
  const sec = dt.getSeconds();

  return <div style={{ textAlign: 'center', fontWeght: 700 }}>{dt.getMinutes()}:{sec < 10 && '0'}{sec}</div>
};

export default Timer;
